<template>
  <v-dialog
    persistent
    v-model="dialog"
    :max-width="isImageOrPdf ? '1024px' : '800px'"
    scrollable
  >
    <v-card light color="white">
      <v-card-title class="d-flex justify-space-between">
        <v-toolbar fixed flat>
              <span
                class="headline neuter--text font-weight-bold"
              >{{ $t('general.titles.details') }}</span
              >
          <v-spacer />
          <div class="d-flex justify-center align-center">
            <p class="ma-0 pa-0 mr-2" style="font-size: 16px">{{ $t('documents.fields.visibility') }}: </p>
            <span class="visibility" v-if="documentSelected.externalVisibility">
                  {{ $t('documents.labels.options.visible') }}
                </span>
            <span class="not-visibility" v-else>
                  {{ $t('documents.labels.options.notVisible') }}
                </span>
          </div>

        </v-toolbar>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-6">
        <v-container style="display: contents" class="ma-0">
          <v-row justify="space-between" v-if="dialog">
            <v-col :cols="isImageOrPdf ? 5 : 12">
              <div
                :class="
                      isImageOrPdf
                        ? 'd-flex flex-column flex-wrap'
                        : 'd-flex flex-wrap'
                    "
              >
                <div style="width: 400px">
                  <div
                    class="ma-2 pa-2 dialog-box__content"
                  >
                    <p
                      class="black--text mb-0 dialog-box__content-title"
                    >
                      {{ $t('documents.fields.authority') }}
                    </p>
                    <p
                      class="black--text mb-0 dialog-box__content-subtitle"
                    >
                      {{ documentSelected.documentAuthority.name }}
                    </p>
                  </div>
                </div>
                <div style="width: 400px">
                  <div
                    class="ma-2 pa-2 dialog-box__content"
                  >
                    <p
                      class="black--text mb-0 dialog-box__content-title"
                    >
                      {{ $t('documents.fields.type') }}
                    </p>
                    <p
                      class="black--text mb-0 dialog-box__content-subtitle"
                    >
                      {{
                        translate(
                          documentSelected.documentType.language_key,
                          true
                        )
                      }}
                    </p>
                  </div>
                </div>
                <div style="width: 400px">
                  <div
                    class="ma-2 pa-2 dialog-box__content"
                  >
                    <p
                      class="black--text mb-0 dialog-box__content-title"
                    >
                      {{ $t('documents.fields.description') }}
                    </p>
                    <p
                      class="black--text mb-0 dialog-box__content-subtitle"
                    >
                      {{
                        translate(
                          documentSelected.documentDescription.language_key,
                          true
                        )
                      }}
                    </p>
                  </div>
                </div>
                <div style="width: 400px">
                  <div
                    class="ma-2 pa-2 dialog-box__content"
                  >
                    <p
                      class="black--text mb-0 dialog-box__content-title"
                    >
                      {{ $t('documents.fields.document_date') }}
                    </p>
                    <p
                      class="black--text mb-0 dialog-box__content-subtitle"
                    >
                      {{ getDateFormat(documentSelected.document_date) }}
                    </p>
                  </div>
                </div>
                <div style="width: 400px">
                  <div
                    class="ma-2 pa-2 dialog-box__content"
                  >
                    <p
                      class="black--text mb-0 dialog-box__content-title"
                    >
                      {{ $t('documents.fields.expiration_date') }}
                    </p>
                    <p
                      class="black--text mb-0 dialog-box__content-subtitle"
                    >
                      <span v-if="documentSelected.expiration_date && documentSelected.expiration_date !== '-'">
                        {{ getDateFormat(documentSelected.expiration_date) }}
                      </span>
                      <v-chip v-else disabled small>
                        N/A
                      </v-chip>
                    </p>
                  </div>
                </div>
                <div style="width: 400px">
                  <div
                    class="ma-2 pa-2 dialog-box__content"
                  >
                    <p
                      class="black--text mb-0 dialog-box__content-title"
                    >
                      {{ $t('reports.titles.localizations') }}
                    </p>
                    <p
                      class="black--text mb-0 dialog-box__content-subtitle"
                      v-for="(
                            storage, index
                          ) in documentSelected.storage_device_names"
                      :key="index"
                    >
                      {{ storage }}
                    </p>
                  </div>
                </div>
                <div style="width: 400px">
                  <div
                    class="ma-2 pa-2 dialog-box__content"
                  >
                    <p
                      class="black--text mb-0 dialog-box__content-title"
                    >
                      {{ $t('documents.fields.season') }}
                    </p>
                    <p
                      class="black--text mb-0 dialog-box__content-subtitle"
                    >
                      {{ documentSelected.season ? translate(documentSelected.season.languageKey) : '-' }}
                    </p>
                  </div>
                </div>
                <div style="width: 400px">
                  <div
                    class="ma-2 pa-2 dialog-box__content"
                  >
                    <p
                      class="black--text mb-0 dialog-box__content-title"
                    >
                      {{ $t('documents.fields.public') }}
                    </p>
                    <p
                      class="black--text mb-0 dialog-box__content-subtitle"
                    >
                      {{ documentSelected.isPublic ? $t('documents.labels.options.visible') : $t('documents.labels.options.notVisible') }}
                    </p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col
              v-if="isImageOrPdf"
              cols="7"
              class="d-flex justify-space-between"
            >
              <v-card
                :light="true"
                outlined
                :width="width"
                min-width="520"
                :max-width="width"
                elevation="0"
                color="white"
              >
                <v-card-text>
                  <v-container
                    v-if="
                          ['jpg', 'png', 'jpeg'].includes(
                            documentSelected.file_extension
                          )
                        "
                  >
                    <v-row justify="center">
                      <v-container class="overflow-x-auto relative">
                        <v-img
                          :lazy-src="documentSelected.s3_url"
                          contain
                          :aspect-ratio="16/9"
                          :width="width"
                          min-width="400"
                          :max-width="width"
                          :src="`${documentSelected.s3_url}`"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-container>
                    </v-row>
                    <v-row justify="center">
                      <v-btn x-large icon @click="zoomLess">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <v-btn x-large icon @click="zoomPlus">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-row>
                  </v-container>
                  <v-container
                    v-else-if="documentSelected.file_extension === 'pdf'"
                  >
                    <v-row justify="center">
                      <pdf
                        :src="`${documentSelected.s3_url}`"
                        @num-pages="pageCount = $event"
                        @page-loaded="currentPage = $event"
                        :page="currentPage"
                        style="width: 60%"
                      />
                    </v-row>
                    <v-row justify="center">
                      <v-btn
                        small
                        class="mr-2"
                        x-large
                        icon
                        @click="backPage"
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <span class="title pt-2"
                      >{{ currentPage }} / {{ pageCount }}</span
                      >
                      <v-btn
                        small
                        class="ml-2"
                        x-large
                        icon
                        @click="nextPage"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="documentSelected.observations">
            <v-col cols="12">
              <v-card-title style="color: black;">
                {{ $t('documents.fields.observations') }}
              </v-card-title>
              <v-card-subtitle style="color: black;">
                {{ documentSelected.observations }}
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions class="pa-6">
        <v-btn
          class="mr-1"
          text
          small
          icon
          :loading="loadingSaveDocument"
        >
          <template v-slot:loader>
            <v-progress-circular
              style="position: absolute; margin-left: -3px; height: 15px"
              color="secondary accent-4"
              indeterminate
              rounded
            />
          </template>
          <v-icon
            color="neuter"
            @click="downloadDocShow(documentSelected)"
          >
            mdi-file-download-outline
          </v-icon>
        </v-btn>
        <v-btn
          v-if="['jpg', 'png', 'jpeg', 'pdf'].includes(documentSelected.file_extension)"
          text
          small
          icon
        >
          <v-icon @click="printDoc(documentSelected.s3_url, documentSelected.file_extension)"
                  color="neuter"
          >
            mdi-printer-outline
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          small
          elevation="0"
          class="t-bw-primary--text"
          color="primary"
          @click="closeDetailsDialog()"
        >
          {{ $t('general.buttons.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import pdf from "vue-pdf";
import LanguageService from "@/services/LanguajeService";
import printJs from "print-js";
import moment from "moment";
import DocumentsService from "@/services/DocumentsService";
import i18n from "@/plugins/i18n";

export default {
  name: "MoreDetailsComponent",
  components: { pdf },

  props: {
    dialog: {
      type: Boolean|undefined,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    loadingPrint: false,
    loadingSaveDocument: false,
    currentPage: 1,
    pageCount: 0,
    urlPDF: '',
    width: 400,
  }),

  computed: {
    documentSelected: {
      get() {
        return this.$store.getters['documents/document']
      },
      set(val) {
        this.$store.commit('documents/SET_DOCUMENT', val)
      },
    },
    isImageOrPdf() {
      return ['jpg', 'png', 'jpeg', 'pdf'].includes(
        this.documentSelected.file_extension
      )
    },
  },

  async mounted() {
    this.$root.$on('download:document', async (item) => {
      if (item.id === this.item.id) {
        await this.downloadDocShow(item);
      }
    });
    await this.$root.$on('print:document', async (item) => {
      await this.printDoc(item.s3_url, item.file);
    })
  },

  methods: {
    async downloadDocShow(item) {
      this.loadingSaveDocument = true
      this.$emit('loading:download', { id: item.id, loading: true });
      await this.downloadDoc(false, item, null).finally(() => {
        this.loadingSaveDocument = false
        this.$emit('loading:download', { id: item.id, loading: false });
      })
    },
    async downloadDoc(isVersion, item) {
      try {
        if (isVersion && !item.currentVersion) {
          await DocumentsService.downloadDocumentFormVersion(item.id, item.version).then(
            response => {
              const FileSaver = require('file-saver')
              const blob = new Blob([response.data], {
                type: 'application/zip',
              })
              FileSaver.saveAs(blob, `evidences_v${item.version}.zip`)
            }
          )
        } else {
          await DocumentsService.downloadDocument(item.id).then(
            response => {
              const FileSaver = require('file-saver')
              const blob = new Blob([response.data], {
                type: 'application/zip',
              })
              FileSaver.saveAs(blob, 'evidences.zip')
            }
          )
        }
      } catch (error) {
        this.loadingSaveDocument = false
        this.$emit('loading:download', false);
        this.$toast.info(i18n.t('documents.notification.evidencesCero'), {
          icon: 'mdi-information-slab-circle',
          queueable: true
        })
      }
    },
    async printDoc(file, extension) {
      if(['jpg', 'png', 'jpeg'].includes(extension)) {
        this.loadingPrint = true;
        await printJs({
          printable: file,
          type: 'image',
          imageStyle:
            'display:block;margin-left:auto;margin-right:auto;height:auto;width:100%',
          documentTitle: 'Documents',
        })
      } else {
        await printJs({ printable: file, type: 'pdf', documentTitle: 'Documents' })
      }

      this.loadingPrint = false;
    },
    zoomPlus() {
      if (this.width + 50 >= 1000) {
        return
      }
      return (this.width += 50)
    },

    zoomLess() {
      if (this.width - 50 <= 400) {
        return
      }
      return (this.width -= 50)
    },
    nextPage() {
      if (this.pageCount >= this.currentPage + 1) this.currentPage++
    },
    backPage() {
      if (this.currentPage > 1) this.currentPage--
    },
    getDateFormat(dateString) {
      return moment(new Date(dateString)).format('DD/MM/YYYY')
    },
    translate(key, uppercase) {
      return LanguageService.getKey3(key)
    },
    closeDetailsDialog() {
      this.$store.commit("documents/SET_ORIGINAL_STATE_DOCUMENT")
      this.$root.$emit('clear:form');
      // this.$refs.expiration_dateRef.clearDate()
      // this.$refs.document_dateRef.clearDate()
      this.$emit('close:dialog', false);
    },
  },

  destroyed() {
    this.$root.$off('download:document');
    this.$root.$off('print:document');
  }
}
</script>

<style scoped>

</style>